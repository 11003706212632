exports.components = {
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-preferred-partners-js": () => import("./../../../src/pages/about/preferred-partners.js" /* webpackChunkName: "component---src-pages-about-preferred-partners-js" */),
  "component---src-pages-about-strategic-partnerships-js": () => import("./../../../src/pages/about/strategic-partnerships.js" /* webpackChunkName: "component---src-pages-about-strategic-partnerships-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-emoney-emoney-client-login-js": () => import("./../../../src/pages/emoney/emoney-client-login.js" /* webpackChunkName: "component---src-pages-emoney-emoney-client-login-js" */),
  "component---src-pages-emoney-emoney-tree-js": () => import("./../../../src/pages/emoney/emoney-tree.js" /* webpackChunkName: "component---src-pages-emoney-emoney-tree-js" */),
  "component---src-pages-emoney-index-js": () => import("./../../../src/pages/emoney/index.js" /* webpackChunkName: "component---src-pages-emoney-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-index-js": () => import("./../../../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-process-js": () => import("./../../../src/pages/services/process.js" /* webpackChunkName: "component---src-pages-services-process-js" */)
}

